import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Article, ArticleSection } from "../../../styledComponents/article"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../../styledComponents/heroBanner"
import Icon from "../../../components/icon"
import { FadingBackground } from "../../../components/modalFadingBackground"
import Modal from "../../../components/modal"

const WordPressIndex = ({ data, location, createCookie }) => {

  return (
    <Layout location={location}>
      <Seo
        title="Wordpress Support & Maintenance"
        description="WordPress Help & Support. WordPress Maintenance plans. Technical support, WordPress hosting & website maintenance."
        image=""
        url=""
      />
      <HeroTextImageWrapper backgroundColor="var(--orange-mid)" gridRowGap="0">
        <StaticImage
          alt=""
          layout="fullWidth"
          // // You can optionally force an aspect ratio for the generated image
          // aspectRatio={1 / 1}
          // // This is a presentational image, so the alt should be an empty string
          // alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={
            "../../../images/wordpress.png"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1}>
            WordPress Support & Maintenance
          </HeroHeader>
          
        </HeroText>
      </HeroTextImageWrapper>
      
      <Article>
        <ArticleSection isList>
          
          <figure>
            <StaticImage src="../../../images/wordpress-core-update-screenshot.png" className="article-inline-image has-caption" alt="" />
            <figcaption>A screenshot showing there is an update to WordPress needed.</figcaption>
          </figure>

          <h2>Why You Should Always Use the Latest Version of WordPress</h2>

          <p>WordPress is open source software with a massive community of contributors working tirelessly to improve it. This kind of development input is important to keeping WordPress fast and secure, but it also provides for many updates throughout the course of a year. Just like any piece of software, updating it is not only important, but necessary to ensure a functioning, high performing website. Let’s look at the key reasons why it is important to update WordPress.</p>


          <h3>Security</h3>
          <p>Security is the primary reason you should keep your WordPress site up to date; updates often feature security enhancements that prevent sites from being exploited. Since WordPress is open source, it benefits from a community of developers and security experts who are constantly testing every version of the software and properly reporting security fixes. Failing to update the latest version of WordPress can make your site an easy target for hackers and malicious code distributors.</p>


          <h3>Bug Fixes</h3>
          <p>Although every major release of WordPress goes through a rigorous testing process, sometimes smaller bugs slip through the cracks. This may or may not affect your website, but it is always a good idea to update WordPress in order to reduce any issues caused by bugs.</p>

          <h3>Speed</h3>
          <p>Web technology is constantly changing and WordPress developers are always searching for ways to make WordPress run faster and more efficiently. By keeping your WordPress site up to date you will provide not only a better user experience, but improve your site’s search engine rankings.</p>

          <h3>New Features</h3>
          <p>Every major release of WordPress includes new features tailored to improve the user experience. Past improvements include the ability to easily include embeds in text and video widgets, a redesigned plugin directory and inline link editing via the visual post editor. Updating WordPress will only improve your ability to add new content to your site.</p>


          <figure>
            <StaticImage src="../../../images/wordpress-plugin-updates.png" className="article-inline-image has-caption" alt="" />
            <figcaption>A screenshot showing there are plugins in WordPress that need updating.</figcaption>
          </figure>



          <h2>Why It's Crucial to Keep Your WordPress Plugins Up-to-Date</h2>

          <p>Keeping your WordPress platform, plugins and themes up-to-date is one of the most critical best practices. Many experienced users and WordPress professionals recommend automatic updates on a regular schedule.</p>

          <p>There are several compelling reasons to update your WordPress plugins. First and foremost, it helps keep your website secure since updates often address known vulnerabilities in plugin code. Skipping updates could lead to hackers exploiting site weaknesses and launching an attack.</p>

          <p>In addition, using older plugin versions could result in compatibility issues with other plugins, themes, or even WordPress core. As you add new features or updates to your site, outdated plugins may not integrate well with the changes, causing various errors.</p>

          <p>Lastly, neglecting plugin updates means you'll miss out on improved functionality and exciting new developments. Updates frequently introduce new features or fix bugs that could make plugins more useful to you.</p>

          <p>Given the multiple reasons to update WordPress plugins, it's critical to include this task in your website management checklist. However, you must update plugins carefully to avoid potential drawbacks.</p>

          <h2>We Created The Ultimate Combined WordPress Hosting, Support & Maintenance Solution That Our Clients Love</h2>
          <p>Having provided support, maintenance and managed hosting to businesses for many years, we understand the main drivers for website success.
            Speed, security and reliability.</p>
          <p>Our Support Unlimited WordPress Care Plans are built around exactly that.</p>
        </ArticleSection>
      </Article>
      <HeroTextImageWrapper
        backgroundColor="var(--orange-mid)"
      >
        <StaticImage
          alt=""
          layout="fullWidth"
          src={
            "../../images/contact-us-lets-talk.jpg"
          }
          formats={["auto", "webp", "avif"]}
          className="padding1"
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1}>Let's talk</HeroHeader>
          <HeroDescription>Conact us today to see how we can help your business online.</HeroDescription>
          <ModalProvider backgroundComponent={FadingBackground}>
            <Modal
              title="Contact Us"
              type="hero"
              serviceName="Website Design Optimisation"
              buttonLabel="Say hello 👋"
              buttonStyle="HeroButton"
              formExcerpt="Please fill in the following form and one of our team will get back to you within 24 hours."
            />
          </ModalProvider>
        </HeroText>
      </HeroTextImageWrapper>
    </Layout>
  )
}

export default WordPressIndex
